import List from '@/pages/admin/memos/List.vue';
import Show from '@/pages/admin/memos/Show.vue';

export default [
  {
    path: 'memos',
    name: 'memos',
    component: List,
  },
  {
    path: 'memos/:id(\\d+)',
    name: 'memo',
    component: Show,
  },
];
