<template>
  <div>
    <base-page-heading title="Memos">
      <template #extra>
        <b-button
          v-if="$can('create', 'Memo')"
          variant="primary"
          class="mr-2"
          v-b-modal.memo-add-modal
          v-b-tooltip.hover
          title="Create a New Memo"
        >
          <i class="fa fa-plus mr-1" />
          Create Memo
        </b-button>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <memos-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <memos-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
    <memos-new-modal
      v-if="$can('create', 'Memo')"
      :user="$store.getters.user"
    />
  </div>
</template>

<script>
import MemosTable from '@/components/admin/memos/Table.vue';
import MemosListFilter from '@/components/admin/memos/ListFilter.vue';
import MemosNewModal from '@/components/admin/memos/NewModal.vue';
import listMixin from '@/mixins/listMixin';
import { MEMO_TABLE_NAME } from '@/constants/memos';

export default {
  name: 'MemosList',
  components: {
    MemosListFilter,
    MemosTable,
    MemosNewModal,
  },
  mixins: [listMixin],
  props: {
    text: String,
    location_id: String,
    client_id: String,
    date_from: String,
    date_to: String,
    date_between: String,
  },
  data() {
    return {
      tableName: MEMO_TABLE_NAME,
      filters: {
        text: this.text,
        location_id: this.location_id,
        client_id: this.client_id,
        date_from: this.date_from,
        date_to: this.date_to,
        date_between: this.date_between,
      },
    };
  },
};
</script>
