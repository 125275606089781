import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const MEMO_MODEL_NAME = 'memos';
export const MEMO_TABLE_NAME = 'memos';

// Vendor label constants
export const MEMO_TITLE_LABEL = 'Title';
export const MEMO_CALLER_LABEL = 'Caller';
export const MEMO_CONTENT_LABEL = 'Content';
export const MEMO_DATE_LABEL = 'Date';
export const MEMO_LOCATION_ID_LABEL = 'Location';
export const MEMO_CLIENT_ID_LABEL = 'Client';
export const MEMO_USER_ID_LABEL = 'Created By';
export const MEMO_ASSIGNED_TO_ID_LABEL = 'Assigned User';

export const MEMO_FORM_LABELS = {
  title: MEMO_TITLE_LABEL,
  caller: MEMO_CALLER_LABEL,
  content: MEMO_CONTENT_LABEL,
  date: MEMO_DATE_LABEL,
  location_id: MEMO_LOCATION_ID_LABEL,
  client_id: MEMO_CLIENT_ID_LABEL,
  user_id: MEMO_USER_ID_LABEL,
  assigned_to_id: MEMO_ASSIGNED_TO_ID_LABEL,
  assigned_to: MEMO_ASSIGNED_TO_ID_LABEL,
};

export const MEMO_TABLE_COLUMNS = {
  title: { label: MEMO_TITLE_LABEL, key: 'title', sortable: true },
  caller: { label: MEMO_CALLER_LABEL, key: 'caller' },
  content: { label: MEMO_CONTENT_LABEL, key: 'content' },
  date: { label: MEMO_DATE_LABEL, key: 'date', sortable: true },
  client_id: { label: MEMO_CLIENT_ID_LABEL, key: 'client_id' },
  location_id: { label: MEMO_LOCATION_ID_LABEL, key: 'location_id' },
  user_id: { label: MEMO_USER_ID_LABEL, key: 'user_id', sortable: true },
  assigned_to_id: { label: MEMO_ASSIGNED_TO_ID_LABEL, key: 'assigned_to_id' },
  updated_at: { label: 'Last Modified', key: 'updated_at', sortable: true },
  date_between: { label: 'Created Between', key: 'date_between', filterOnly: true },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const MEMO_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(MEMO_TABLE_COLUMNS);

export const MEMO_TABLE_DEFAULT_COLUMNS = [
  MEMO_TABLE_COLUMNS.title,
  MEMO_TABLE_COLUMNS.date,
  MEMO_TABLE_COLUMNS.location_id,
  MEMO_TABLE_COLUMNS.assigned_to_id,
];
