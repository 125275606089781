<template>
  <div>
    <base-page-heading title="Find Locations without Feedback" />
    <div class="content">
      <location-search-list-filter
        class="mb-0"
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <location-search-table
        class="mt-3"
        :filters="filters"
        :columns="columns"
        v-bind="{ ...sorting, ...pagination }"
      />
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import { DateTime } from 'luxon';
import { CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME } from '@/constants/reports';
import LocationSearchListFilter from '@/components/admin/reports/clientFeedbacks/LocationSearchListFilter.vue';
import LocationSearchTable from '@/components/admin/reports/clientFeedbacks/LocationSearchTable.vue';

export default {
  name: 'ClientFeedbacksLocationSearchReport',
  components: {
    LocationSearchTable,
    LocationSearchListFilter,
  },
  mixins: [listMixin],
  props: {
    text: String,
    client_id: String,
    location_id: String,
    feedback_last_received_at: String,
  },
  data() {
    return {
      tableName: CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME,
      filters: {
        text: this.text,
        client_id: this.client_id || this.$store.getters?.client?.id,
        location_id: this.location_id,
        feedback_last_received_at: this.feedback_last_received_at || DateTime.now().minus({ months: 1 }).startOf('month').toISODate(),
      },
    };
  }
};
</script>
