<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols-lg="6">
        <b-form-group
          :label="fieldLabels.number"
          label-for="quote-number"
        >
          <b-form-input
            id="quote-number"
            v-model="$v.form.number.$model"
            :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.number.$dirty"
            id="quote-number-feedback"
          >
            <span v-if="!$v.form.number.required">Please enter a number.</span>
            <span v-if="!$v.form.number.maxLength">
              The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols-lg="6">
        <b-form-group
          :label="fieldLabels.customer_identifier"
          label-for="quote-customer_identifier"
        >
          <b-form-input
            id="quote-customer_identifier"
            v-model="$v.form.customer_identifier.$model"
            :state="$v.form.customer_identifier.$dirty ? !$v.form.customer_identifier.$error : null"
            placeholder="Optional"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.customer_identifier.$dirty"
            id="quote-customer_identifier-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.customer_identifier.maxLength"> The customer identifier must be less than {{ $v.form.customer_identifier.$params.maxLength.max + 1 }} characters.</span>
            <span v-if="!$v.form.customer_identifier.serverFailed">
              {{ serverErrors.customer_identifier }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols-lg="6">
        <b-form-group
          :label="fieldLabels.date"
          label-for="quote-date"
        >
          <sfs-date-picker
            id="quote-date"
            v-model="form.date"
            :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
            :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.date.$dirty"
            id="quote-date-feedback"
          >
            <span v-if="!$v.form.date.required">Please enter a quote date.</span>
            <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols-lg="6">
        <b-form-group
          :label="fieldLabels.due_date"
          label-for="quote-due_date"
        >
          <sfs-date-picker
            id="quote-due_date"
            v-model="form.due_date"
            placeholder="Optional"
            :class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
            :with-class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.due_date.$dirty"
            id="quote-due_date-feedback"
          >
            <span v-if="!$v.form.due_date.serverFailed">{{ serverErrors.due_date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.service_id"
          label-for="quote-service_id"
        >
          <service-select
            :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error, 'mb-1': true }"
            id="quote-service_id"
            :placeholder="client?.work_order_charges_require_service ? '' : 'Optional'"
            :client-id="client_id"
            client-unassigned
            :value="form.service"
            @input="onServiceChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_id.$dirty"
            id="quote-service_id-feedback"
          >
            <span v-if="!$v.form.service_id.required">Please enter a service.</span>
            <span v-if="!$v.form.service_id.serverFailed">{{ serverErrors.service }}</span>
          </b-form-invalid-feedback>
          <b-form-textarea
            id="quote-description"
            v-model="$v.form.description.$model"
            placeholder="Enter a description"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            rows="4"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            id="quote-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label-for="quote-capital_expenditure">
          <b-form-checkbox
            switch
            id="quote-capital_expenditure"
            v-model="$v.form.capital_expenditure.$model"
          >
            This Quote is a capital expenditure
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="isDollarGeneralFexaQuote">
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.dollar_general_fexa_capex_description"
          label-for="quote-dollar_general_fexa_capex_description"
        >
          <dollar-general-fexa-capex-description-select
            name="quote-dollar_general_fexa_capex_description"
            :value="$v.form.dollar_general_fexa_capex_description.$model"
            @input="onDollarGeneralFexaCapexDescriptionChange"
            :select-class="{ 'is-invalid': $v.form.dollar_general_fexa_capex_description.$dirty && $v.form.dollar_general_fexa_capex_description.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.dollar_general_fexa_capex_description.$dirty"
            class="quote-dollar_general_fexa_capex_description-feedback"
          >
            <span v-if="!$v.form.dollar_general_fexa_capex_description.serverFailed">{{ serverErrors.dollar_general_fexa_capex_description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.dollar_general_fexa_activity_code"
          label-for="quote-dollar_general_fexa_activity_code"
        >
          <dollar-general-fexa-activity-code-select
            name="quote-dollar_general_fexa_activity_code"
            :value="$v.form.dollar_general_fexa_activity_code.$model"
            @input="onDollarGeneralFexaActivityCodeChange"
            :select-class="{ 'is-invalid': $v.form.dollar_general_fexa_activity_code.$dirty && $v.form.dollar_general_fexa_activity_code.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.dollar_general_fexa_activity_code.$dirty"
            class="quote-dollar_general_fexa_activity_code-feedback"
          >
            <span v-if="!$v.form.dollar_general_fexa_activity_code.serverFailed">{{ serverErrors.dollar_general_fexa_activity_code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="quote-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="quote-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ServiceSelect from '@/components/admin/services/Select.vue';
import DollarGeneralFexaCapexDescriptionSelect from '@/components/admin/integrationReferences/DollarGeneralFexa/QuoteItemCapexDescriptionSelect.vue';
import DollarGeneralFexaActivityCodeSelect from '@/components/admin/integrationReferences/DollarGeneralFexa/QuoteItemActivityCodeSelect.vue';

import { QUOTES_FORM_LABELS } from '@/constants/quotes.js';
export default {
  name: 'QuotesForm',
  mixins: [formMixin],
  components: {
    ServiceSelect,
    DollarGeneralFexaCapexDescriptionSelect,
    DollarGeneralFexaActivityCodeSelect,
  },
  props: {
    isDollarGeneralFexaQuote: {  type: Boolean, default: false },
    isRiteAidFexaQuote: { type: Boolean, default: false },
    client_id: Number,
    location_id: Number,
    service_id: Number,
    number: String,
    date: String,
    due_date: String,
    description: String,
    capital_expenditure: Boolean,
    customer_identifier: String,
    dollar_general_fexa_capex_description: String,
    dollar_general_fexa_activity_code: String,
    client: Object,
    location: Object,
    service: Object,
  },
  data() {
    return {
      form: {
        service_id: this.service_id,
        number: this.number,
        date: this.date,
        due_date: this.due_date,
        description: this.description,
        customer_identifier: this.customer_identifier,
        service: this.service,
        capital_expenditure: this.capital_expenditure,
        dollar_general_fexa_capex_description: this.dollar_general_fexa_capex_description,
        dollar_general_fexa_activity_code: this.dollar_general_fexa_activity_code,
      },
      fieldLabels: {
        ...QUOTES_FORM_LABELS,
        number: 'Number *',
        date: 'Date *',
      },
    };
  },
  validations: {
    form: {
      number: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      customer_identifier: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('customer_identifier');
        },
      },
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      due_date: {
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      service_id: {
        required: requiredIf(function () {
          return this.client?.work_order_charges_require_service;
        }),
        serverFailed() {
          return !this.hasServerErrors('service');
        },
      },
      capital_expenditure: {},
      dollar_general_fexa_capex_description: {
        serverFailed() {
          return !this.hasServerErrors('dollar_general_fexa_capex_description');
        },
      },
      dollar_general_fexa_activity_code: {
        serverFailed() {
          return !this.hasServerErrors('dollar_general_fexa_activity_code');
        },
      },
    },
  },
  methods: {
    onServiceChange(service) {
      this.form.service_id = service ? service.id : null;
      this.form.service = service;
    },
    onDollarGeneralFexaCapexDescriptionChange(description) {
      this.form.dollar_general_fexa_capex_description = description ? description.id : null;
    },
    onDollarGeneralFexaActivityCodeChange(code) {
      this.form.dollar_general_fexa_activity_code = code ? code.id : null;
    },
  },
};
</script>
