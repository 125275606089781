
import { Ability, AbilityBuilder } from '@casl/ability';
import {
  WORK_ORDER_STATUS_PENDING,
  WORK_ORDER_STATUS_UNASSIGNED,
  WORK_ORDER_STATUS_ASSIGNED,
  WORK_ORDER_STATUS_IN_PROGRESS,
  WORK_ORDER_STATUS_CANCELLED,
  WORK_ORDER_STATUS_COMPLETED,
  WORK_ORDER_STATUS_INVOICED,
  TECHNICIAN_WORK_ORDER_STATUS_CURRENT,
  TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS,
  TECHNICIAN_WORK_ORDER_STATUS_COMPLETED,
} from '@/constants/workOrders';

import { USER_ADMIN_CATEGORY, USER_EMPLOYEE_CATEGORY, USER_TECHNICIAN_CATEGORY } from '@/constants/users';
import {
  SUPPLY_ORDER_STATUS_PENDING,
  SUPPLY_ORDER_STATUS_CONFIRMED,
} from '@/constants/supplyOrders';
import {
  QUOTE_STATUS_DRAFT,
  QUOTE_STATUS_CANCELLED,
  QUOTE_STATUS_ACCEPTED,
  QUOTE_STATUS_DECLINED,
} from '@/constants/quotes';

/**
 * This function defines the abilities that a user has for the app.
 * @param {object} user The user to define abilities for.
 */
export function defineRulesFor(user) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  const permissions = user.roles.map((role) => role.permissions);

  for (const permission of permissions) {
    for (const key in permission) {
      const class_name = key;
      const actions = permission[key];

      for (const action of actions) {
        can(action, classifyString(class_name));
      }
    }
  }

  if (isScopesEmployee(user)) {
    cannot('update', 'InvoiceItem', { finalized_at: { $nin: [null, undefined] } });
    cannot('destroy', 'InvoiceItem', { finalized_at: { $nin: [null, undefined] } });

    cannot('update', 'Invoice', { finalized_at: { $nin: [null, undefined] } });
    cannot('create_item', 'Invoice', { finalized_at: { $nin: [null, undefined] } });

    cannot('destroy', 'Invoice', { finalized_at: { $nin: [null, undefined] } });
    cannot('destroy_with_items', 'Invoice', { finalized_at: { $nin: [null, undefined] } });

    cannot('propose', 'Quote', { status: { $nin: [QUOTE_STATUS_DRAFT] } });
    cannot('accept', 'Quote', { status: { $in: [QUOTE_STATUS_CANCELLED, QUOTE_STATUS_ACCEPTED] } });
    cannot('decline', 'Quote', { status: { $in: [QUOTE_STATUS_CANCELLED, QUOTE_STATUS_DECLINED] } });
    cannot('cancel', 'Quote', { status: { $in: [QUOTE_STATUS_CANCELLED] } });
    cannot('uncancel', 'Quote', { status: { $nin: [QUOTE_STATUS_CANCELLED] } });

    cannot('update', 'SupplyOrderItem', { invoiced: true });
    cannot('destroy', 'SupplyOrderItem', { invoiced: true });

    cannot('confirm', 'SupplyOrder', { status: { $nin: [SUPPLY_ORDER_STATUS_PENDING] } });
    cannot('invoice', 'SupplyOrder', { status: { $nin: [SUPPLY_ORDER_STATUS_CONFIRMED] } });

    cannot('update', 'TechnicianPaymentItem', { finalized_at: { $nin: [null, undefined] } });
    cannot('destroy', 'TechnicianPaymentItem', { finalized_at: { $nin: [null, undefined] } });

    cannot('update', 'TechnicianPayment', { finalized_at: { $nin: [null, undefined] } });
    cannot('create_item', 'TechnicianPayment', { finalized_at: { $nin: [null, undefined] } });
    cannot('approve', 'TechnicianPayment', { approved_at: { $nin: [null, undefined] }, finalized_at: { $nin: [null, undefined] } });

    if (isEmployee(user)) {
      cannot('update', 'User', { category: { $in: [USER_ADMIN_CATEGORY, USER_EMPLOYEE_CATEGORY, USER_TECHNICIAN_CATEGORY] } });
    }
    can('update', 'User', { id: user.id }); // Users can upadte themselves.

    cannot('update', 'WorkOrderCharge', { paid: true, invoiced: true });
    cannot('destroy', 'WorkOrderCharge', { paid: true });
    cannot('destroy', 'WorkOrderCharge', { invoiced: true });

    cannot('cancel', 'WorkOrder', { status: { $in: [WORK_ORDER_STATUS_CANCELLED, WORK_ORDER_STATUS_INVOICED] } });
    cannot('uncancel', 'WorkOrder', { status: { $nin: [WORK_ORDER_STATUS_CANCELLED] } });
    cannot('assign', 'WorkOrder', { status: { $nin: [WORK_ORDER_STATUS_PENDING, WORK_ORDER_STATUS_UNASSIGNED] } });
    cannot('reassign', 'WorkOrder', { status: { $nin: [WORK_ORDER_STATUS_ASSIGNED] } });
    cannot('complete', 'WorkOrder', { status: { $nin: [WORK_ORDER_STATUS_PENDING, WORK_ORDER_STATUS_UNASSIGNED, WORK_ORDER_STATUS_ASSIGNED, WORK_ORDER_STATUS_IN_PROGRESS] } });
    cannot('undo_completion', 'WorkOrder', { status: { $nin: [WORK_ORDER_STATUS_IN_PROGRESS, WORK_ORDER_STATUS_COMPLETED] } });
  } else {
    cannot('reassign', 'WorkOrder', { status: { $nin: [TECHNICIAN_WORK_ORDER_STATUS_CURRENT] } });
    cannot('upload_photo', 'WorkOrder', { status: { $nin: [TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS, TECHNICIAN_WORK_ORDER_STATUS_COMPLETED] } });
    cannot('delete_photo', 'WorkOrder', { status: { $nin: [TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS, TECHNICIAN_WORK_ORDER_STATUS_COMPLETED] } });
  }

  return rules;
}

export function defineAbilityFor(user) {
  return new Ability(defineRulesFor(user));
}

export function classifyString(str) {
  const splittedString = str.slice(0, -1).split('_');
  // certain classes don't convert properly, so we can do a manual check.
  if (str == 'gl_codes') {
    return 'GLCode';
  }
  else if(str =='supplies') {
    return 'Supply';
  } else {
    return splittedString.map((word) => { return word[0].toUpperCase() + word.slice(1); }).join('');
  }
}

function isScopesEmployee(user) {
  return [USER_ADMIN_CATEGORY, USER_EMPLOYEE_CATEGORY].includes(user.category);
}

function isEmployee(user) {
  return user.category == USER_EMPLOYEE_CATEGORY;
}
