<template>
  <editor
    ref="toastEditor"
    :initial-value="value"
    :options="editorOptions"
    initial-edit-type="wysiwyg"
    @blur="onEditorBlur"
  />
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';

export default {
  name: 'MarkdownEditor',
  components: {
    editor: Editor
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      editorOptions: {
        hideModeSwitch: true,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'indent', 'outdent'],
          ['table', 'link'],
        ]
      }
    };
  },
  methods: {
    onEditorBlur() {
      this.$emit('input', this.$refs.toastEditor.invoke('getMarkdown'));
    }
  }
};
</script>
