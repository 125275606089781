<template>
  <div>
    <base-page-heading title="Work Orders">
      <template #extra>
        <b-button
          v-if="$can('create', 'WorkOrder')"
          variant="primary"
          @click="() => $router.push({ name: 'new-work-order' })"
          v-b-tooltip.hover
          title="Create a new Work Order"
        >
          <i class="fa fa-plus mr-1" />
          New Work Order
        </b-button>
        <b-dropdown
          v-if="$can('create', 'Import')"
          text="Import"
          class="ml-2"
          variant="outline-secondary"
          right
          v-b-tooltip.hover
          title="Manage Work Orders using imports"
        >
          <template #button-content>
            <i class="fa fa-file-excel mr-1" />
            Import
          </template>
          <b-dropdown-group>
            <b-dropdown-item v-b-modal.work-orders-imports-add-modal>
              New
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.work-order-charges-imports-add-modal>
              New Charges
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.update-work-orders-imports-add-modal>
              Update
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.assign-work-orders-imports-add-modal>
              Assign
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.check-out-work-orders-imports-add-modal>
              Check Out
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.cancel-work-orders-imports-add-modal>
              Cancel
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.reopen-work-orders-imports-add-modal>
              Reopen Cancelled
            </b-dropdown-item>
            <b-dropdown-divider />
          </b-dropdown-group>

          <b-dropdown-group header="3rd Party Integrations">
            <b-dropdown-item v-b-modal.officetrax-work-orders-imports-add-modal>
              Edit Officetrax Data
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.officetrax-work-orders-photos-push-imports-add-modal>
              Push Photos to Officetrax
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.officetrax-work-orders-completions-push-imports-add-modal>
              Push Completions to Officetrax
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.service-channel-work-orders-photos-push-imports-add-modal>
              Push Photos to ServiceChannel
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.service-channel-work-orders-push-imports-add-modal>
              Push Completions to ServiceChannel
            </b-dropdown-item>
          </b-dropdown-group>
        </b-dropdown>

        <b-dropdown
          class="ml-2"
          variant="outline-secondary"
          right
          v-b-tooltip.hover
          title="Download spreadsheet"
        >
          <template #button-content>
            <b-spinner
              v-if="downloading"
              small
              label="Downloading..."
            />
            <i
              v-else
              class="fa fa-download mr-1"
            />
            Downloads
          </template>
          <b-dropdown-item @click="downloadSpreadsheet">
            Work Orders
          </b-dropdown-item>
          <b-dropdown-item @click="downloadChargesSpreadsheet">
            Charges
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isCMSOrganization"
            @click="downloadCMSOpsSpreadsheet"
          >
            CMS Ops Export
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item v-b-modal.work-order-email-multiple-modal>
            Email Work Order PDFs
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <work-orders-list-filter
            :columns="columns"
            :initial-filters="filters"
            :total-rows="totalRows"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <work-orders-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>

    <!-- Page Modals -->
    <work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <work-order-charges-import-new-modal v-if="$can('create', 'Import')" />
    <assign-work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <check-out-work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <cancel-work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <update-work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <reopen-work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <officetrax-work-orders-import-new-modal v-if="$can('create', 'Import')" />
    <service-channel-work-orders-push-import-new-modal v-if="$can('create', 'Import')" />
    <service-channel-work-orders-photos-push-import-new-modal v-if="$can('create', 'Import')" />
    <officetrax-work-orders-photos-push-import-new-modal v-if="$can('create', 'Import')" />
    <officetrax-work-orders-completions-push-import-new-modal v-if="$can('create', 'Import')" />
    <work-orders-email-multiple-modal :filters="filters" />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import downloadMixin from '@/mixins/downloadMixin';
import { WORK_ORDER_TABLE_NAME } from '@/constants/workOrders';
import WorkOrdersImportNewModal from '@/components/admin/imports/WorkOrdersNewModal.vue';
import WorkOrderChargesImportNewModal from '@/components/admin/imports/WorkOrderChargesNewModal.vue';
import AssignWorkOrdersImportNewModal from '@/components/admin/imports/AssignWorkOrdersNewModal.vue';
import CheckOutWorkOrdersImportNewModal from '@/components/admin/imports/CheckOutWorkOrdersNewModal.vue';
import CancelWorkOrdersImportNewModal from '@/components/admin/imports/CancelWorkOrdersNewModal.vue';
import UpdateWorkOrdersImportNewModal from '@/components/admin/imports/UpdateWorkOrdersNewModal.vue';
import ReopenWorkOrdersImportNewModal from '@/components/admin/imports/ReopenWorkOrdersNewModal.vue';
import OfficetraxWorkOrdersImportNewModal from '@/components/admin/imports/OfficetraxWorkOrdersNewModal.vue';
import OfficetraxWorkOrdersPhotosPushImportNewModal from '@/components/admin/imports/OfficetraxWorkOrdersPhotosPushNewModal.vue';
import OfficetraxWorkOrdersCompletionsPushImportNewModal from '@/components/admin/imports/OfficetraxWorkOrdersCompletionsPushNewModal.vue';
import ServiceChannelWorkOrdersPushImportNewModal from '@/components/admin/imports/ServiceChannelWorkOrdersPushNewModal.vue';
import ServiceChannelWorkOrdersPhotosPushImportNewModal from '@/components/admin/imports/ServiceChannelWorkOrdersPhotosPushNewModal.vue';
import WorkOrdersListFilter from '@/components/admin/workOrders/ListFilter.vue';
import WorkOrdersTable from '@/components/admin/workOrders/Table.vue';
import WorkOrdersEmailMultipleModal from '@/components/admin/workOrders/EmailMultipleModal.vue';

export default {
  name: 'WorkOrdersList',
  components: {
    WorkOrdersImportNewModal,
    WorkOrderChargesImportNewModal,
    AssignWorkOrdersImportNewModal,
    CheckOutWorkOrdersImportNewModal,
    CancelWorkOrdersImportNewModal,
    UpdateWorkOrdersImportNewModal,
    ReopenWorkOrdersImportNewModal,
    OfficetraxWorkOrdersImportNewModal,
    OfficetraxWorkOrdersPhotosPushImportNewModal,
    OfficetraxWorkOrdersCompletionsPushImportNewModal,
    ServiceChannelWorkOrdersPushImportNewModal,
    ServiceChannelWorkOrdersPhotosPushImportNewModal,
    WorkOrdersTable,
    WorkOrdersListFilter,
    WorkOrdersEmailMultipleModal,
  },
  mixins: [listMixin, downloadMixin],
  props: {
    text: String,
    number: String,
    client_id: String,
    location_id: String,
    service_id: String,
    manager_id: String,
    parent_id: String,
    city: String,
    county: String,
    state: String,
    assigned_to_state: String,
    description: String,
    maintainance_type: String,
    date_bucket_start_from: String,
    date_bucket_start_to: String,
    date_bucket_end_from: String,
    date_bucket_end_to: String,
    checked_in_from: String,
    checked_in_to: String,
    checked_out_from: String,
    checked_out_to: String,
    invoice_date_from: String,
    invoice_date_to: String,
    assigned_to_id: String,
    done_by_id: String,
    statuses: Array,
    sub_status: String,
    invoiced: String,
    technician_paid: String,
    completion_sources: Array,
    cancellation_reasons: Array,
    check_in_distance: String,
    no_service: String,
  },
  data() {
    return {
      tableName: WORK_ORDER_TABLE_NAME,
      filters: {
        text: this.text,
        number: this.number,
        client_id: this.client_id || this.$store.getters?.client?.id,
        location_id: this.location_id,
        service_id: this.service_id,
        manager_id: this.manager_id,
        parent_id: this.parent_id,
        city: this.city,
        county: this.county,
        state: this.state,
        assigned_to_state: this.assigned_to_state,
        description: this.description,
        maintainance_type: this.maintainance_type,
        date_bucket_start_from: this.date_bucket_start_from,
        date_bucket_start_to: this.date_bucket_start_to,
        date_bucket_end_from: this.date_bucket_end_from,
        date_bucket_end_to: this.date_bucket_end_to,
        checked_in_from: this.checked_in_from,
        checked_in_to: this.checked_in_to,
        checked_out_from: this.checked_out_from,
        checked_out_to: this.checked_out_to,
        invoice_date_from: this.invoice_date_from,
        invoice_date_to: this.invoice_date_to,
        assigned_to_id: this.assigned_to_id,
        done_by_id: this.done_by_id,
        statuses: this.statuses,
        sub_status: this.sub_status,
        invoiced: this.invoiced,
        technician_paid: this.technician_paid,
        no_service: this.no_service,
        completion_sources: this.completion_sources,
        cancellation_reasons: this.cancellation_reasons,
        check_in_distance: this.check_in_distance,
      },
    };
  },
  methods: {
    downloadSpreadsheet() {
      this.download(this.$workOrdersAPI.getSpreadsheet, this.filters, 'Work Orders.csv');
    },
    downloadCMSOpsSpreadsheet() {
      this.download(this.$workOrdersAPI.getSpreadsheet, { ...this.filters, cms_report: true }, 'Work Orders.csv');
    },
    downloadChargesSpreadsheet() {
      this.download(this.$workOrderChargesAPI.getSpreadsheet, this.filters, 'Charges.csv');
    },
  },
  computed: {
    isCMSOrganization() {
      return this.$store.getters.currentOrganization?.id === 1;
    }
  }
};
</script>
