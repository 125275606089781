<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$clientFeedbacksAPI.getClientFeedbacks"
    provider-key="clientFeedbacks"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    @total-rows-changed="(totalRows) => $emit('total-rows-changed', totalRows)"
  >
    <template #cell(date)="data">
      <div>{{ data.item.date | dateFormat }}</div>
      <b-link
        v-if="data.item.client"
        class="text-secondary"
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(location_id)="data">
      <div v-if="data.item.location">
        <b-link
          :to="{ name: 'location', params: { id: data.item.location_id } }"
          v-b-tooltip.hover.window
          :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
        >
          {{ data.item.location.store_number }}
          <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
        </b-link>
        <div class="text-nowrap font-size-sm text-secondary">
          {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
        </div>
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(work_order_id)="data">
      <div v-if="data.item.work_order">
        <b-link
          :to="{ name: 'work-order', params: { id: data.item.work_order_id } }"
        >
          {{ data.item.work_order.number }}
        </b-link>
        <div
          v-if="data.item.work_order?.checked_out_at"
          class="text-nowrap font-size-sm text-secondary"
        >
          Service Date: {{ data.item.work_order.checked_out_at | dateFormat(data.item.location.time_zone) }}
        </div>
      </div>
    </template>
    <template #cell(actions)="data">
      <sfs-download-button
        :filename="`Client Feedback.pdf`"
        :provider-params="{ id: data.item.id }"
        :file-provider="$clientFeedbacksAPI.getPDF"
        v-b-tooltip.hover
        title="Download the Client Feedback"
        class="p-2 border-0"
      />

      <b-button-group>
        <b-button
          size="sm"
          variant="link"
          v-b-modal="`client-feedbacks-${data.item.id}-custom-field-values-modal`"
          v-b-tooltip.hover
          title="View feedback"
        >
          <i class="far fa-chart-bar" />
        </b-button>
      </b-button-group>
      <custom-field-values-modal
        :key="`client-feedbacks-custom-field-values-modal-${data.item.id}`"
        :value="data.item"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { CLIENT_FEEDBACK_TABLE_DEFAULT_COLUMNS, CLIENT_FEEDBACK_MODEL_NAME } from '@/constants/clientFeedbacks';
import CustomFieldValuesModal from '@/components/admin/clientFeedbacks/CustomFieldValuesModal.vue';
export default {
  name: 'ClientFeedbacksTable',
  mixins: [tablePropsMixin, translationMixin],
  components: {
    CustomFieldValuesModal,
  },
  props: {
    columns: {
      type: Array,
      default: () => CLIENT_FEEDBACK_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: CLIENT_FEEDBACK_MODEL_NAME,
    };
  },
};
</script>
