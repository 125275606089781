import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getMemos(params) {
    return axios.get(adminPath('/memos?') + prepareQueryString(params)).then((response) => {
      return {
        memos: response.data.map((memo) => an('Memo', memo)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/memos/${id}`)).then((response) => an('Memo', response.data));
  },
  getPDF(params) {
    return axios.get(adminPath(`/memos/${params.id}.pdf`), { responseType: 'blob' });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/memos/${id}`), {
        ...data,
      })
      .then((response) => an('Memo', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/memos'), {
        ...data,
      })
      .then((response) => an('Memo', response.data));
  },
  email(id, data) {
    return axios
      .post(adminPath(`/memos/${id}/email`), {
        ...data,
      })
      .then((response) => an('Memo', response.data));
  },
  assign(id, data) {
    return axios
      .patch(adminPath(`/memos/${id}/assign`), {
        ...data,
      })
      .then((response) => an('Memo', response.data));
  },
  destroy(id) {
    return axios.delete(adminPath(`/memos/${id}`));
  },
  getPhotos(id, params) {
    return axios.get(adminPath(`/memos/${id}/photos?`) + prepareQueryString(params)).then((response) => {
      return {
        photos: response.data.map((item) => an('Photo', item)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  destroyMemoPhoto(itemId, photoId) {
    return axios.delete(adminPath(`/memos/${itemId}/photos/${photoId}`));
  },
};
