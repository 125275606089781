<template>
  <div>
    <base-page-heading title="Invoices">
      <template #extra>
        <b-button
          v-if="$can('create', 'Invoice')"
          class="mr-2"
          v-b-modal.invoice-add-modal
          variant="primary"
          v-b-tooltip.hover
          title="Create a new Invoice"
        >
          <i class="fa fa-plus mr-1" />
          New Invoice
        </b-button>

        <b-button
          v-if="$can('finalize', 'TechnicianPayment')"
          class="mr-2"
          v-b-modal.invoice-finalize-modal
          variant="primary"
          v-b-tooltip.hover
          title="Finalize the Invoices"
        >
          <i class="fa fa-check-double" />
          Finalize
        </b-button>

        <b-dropdown
          v-if="$can('create', 'Import') && ($can('create', 'InvoiceItem') || $can('destroy', 'InvoiceItem'))"
          text="Import"
          class="mr-2"
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Manage Invoices using imports"
        >
          <template #button-content>
            <i class="fa fa-file-excel mr-1" />
            Import
          </template>
          <b-dropdown-item
            v-if="$can('create', 'InvoiceItem')"
            v-b-modal.invoice-items-imports-add-modal
          >
            New Invoice Items
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('destroy', 'InvoiceItem')"
            v-b-modal.remove-work-orders-from-invoice-imports-add-modal
          >
            Remove Work Orders From Invoice
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Invoice')"
            v-b-modal.delete-invoices-imports-add-modal
          >
            Delete Invoices
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          v-if="$can('create', 'InvoiceItem')"
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Download spreadsheets"
        >
          <template #button-content>
            <b-spinner
              v-if="downloading"
              small
              label="Downloading..."
            />
            <i
              v-else
              class="fa fa-download mr-1"
            />
            Downloads
          </template>
          <b-dropdown-item @click="emailInvoices">
            Email Invoices
          </b-dropdown-item>
          <b-dropdown-item @click="downloadSpreadsheet">
            Invoices Spreadsheet
          </b-dropdown-item>
          <b-dropdown-item @click="downloadAvalaraExport">
            Avalara Import
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>

    <div class="content">
      <invoices-list-filter
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <invoices-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>

    <!-- Page Modals -->
    <invoices-new-modal v-if="$can('create', 'Invoice')" />
    <invoices-finalize-modal
      v-if="$can('finalize', 'Invoice')"
      @on-save="applyFilters({ columns: columns, filters: { filters } })"
    />
    <remove-work-orders-from-invoice-import-new-modal v-if="$can('destroy', 'InvoiceItem')" />
    <delete-invoices-import-new-modal v-if="$can('destroy', 'Invoice')" />
    <invoice-items-import-new-modal v-if="$can('create', 'Import')" />
  </div>
</template>

<script>
import InvoicesNewModal from '@/components/admin/invoices/NewModal.vue';
import InvoicesListFilter from '@/components/admin/invoices/ListFilter.vue';
import InvoicesTable from '@/components/admin/invoices/Table.vue';
import InvoicesFinalizeModal from '@/components/admin/invoices/FinalizeModal.vue';
import RemoveWorkOrdersFromInvoiceImportNewModal from '@/components/admin/imports/RemoveWorkOrdersFromInvoiceNewModal.vue';
import DeleteInvoicesImportNewModal from '@/components/admin/imports/DeleteInvoicesNewModal.vue';
import InvoiceItemsImportNewModal from '@/components/admin/imports/InvoiceItemsNewModal.vue';
import listMixin from '@/mixins/listMixin';
import downloadMixin from '@/mixins/downloadMixin';
import { INVOICE_TABLE_NAME } from '@/constants/invoices';

export default {
  name: 'InvoicesList',
  components: {
    InvoicesNewModal,
    InvoicesListFilter,
    InvoicesTable,
    RemoveWorkOrdersFromInvoiceImportNewModal,
    DeleteInvoicesImportNewModal,
    InvoiceItemsImportNewModal,
    InvoicesFinalizeModal,
  },
  mixins: [downloadMixin, listMixin],
  props: {
    text: String,
    client_id: String,
    status: String,
    date_from: String,
    date_to: String,
    finalized: String,
    uploaded: String,
    emailed: String,
    emailable: String,
    invoice_delivery_method: String,
  },
  data() {
    return {
      tableName: INVOICE_TABLE_NAME,
      filters: {
        text: this.text,
        client_id: this.client_id || this.$store.getters?.client?.id,
        status: this.status,
        date_from: this.date_from,
        date_to: this.date_to,
        finalized: this.finalized,
        uploaded: this.uploaded,
        emailed: this.emailed,
        emailable: this.emailable,
        invoice_delivery_method: this.invoice_delivery_method,
      },
    };
  },
  methods: {
    downloadSpreadsheet() {
      this.download(this.$invoicesAPI.getSpreadsheet, this.filters, 'Invoices.csv');
    },
    downloadAvalaraExport() {
      this.download(this.$invoicesAPI.getAvalaraImport, this.filters, 'Avalara Import.csv');
    },
    emailInvoices() {
      this.$swal({
        text: 'Are you sure you want to email yourself these invoices?',
        icon: 'info',
        preConfirm: () => {
          return this.$invoicesAPI.emailInvoices(this.filters).catch(() => {
            this.$bvToast.toast('We\'ve experienced an issue while trying to email the invoices, Please try again.', {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
            return false;
          });
        },
      }).then((result) => {
        if (result.value) {
         this.getRecords();
        }
        result.timer;
      });
    },
  },
};
</script>
