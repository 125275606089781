<template>
  <div>
    <b-dropdown
      class="mr-2"
      variant="outline-secondary"
      v-b-tooltip.hover
      title="Manage Officetrax data"
    >
      <template #button-content>
        <span>Fexa</span>
        <span class="font-size-xs bg-gray p-1">
          3rd Party
        </span>
      </template>
      <b-dropdown-item
        v-b-modal="scheduleModalId"
      >
        <i class="fa fa-calendar-alt mr-1" />
        Manage Visit Schedule
      </b-dropdown-item>

      <b-dropdown-item
        v-b-modal="statusModalId"
      >
        <i class="fa fa-wrench mr-1" />
        Update Assignment Status
      </b-dropdown-item>
    </b-dropdown>
    <dollar-general-fexa-assignment-status-form-modal
      v-if="$can('update', 'WorkOrder') && integrationReference"
      :key="statusFormModalKey"
      :form-modal-id="statusModalId"
      :value="integrationReference"
      @on-save="onIntegrationReferenceChange"
      :is-dollar-general-fexa="isDollarGeneralFexa"
    />

    <dollar-general-fexa-visit-schedule-form-modal
      v-if="$can('update', 'WorkOrder') && integrationReference"
      :key="scheduleFormModalKey"
      :form-modal-id="scheduleModalId"
      :recommended-visit-schedule="recommendedVisitDate"
      :value="value"
      @on-save="onWorkOrderChange"
    />
  </div>
</template>

<script>
import DollarGeneralFexaAssignmentStatusFormModal from '@/components/admin/integrationReferences/Fexa/AssignmentStatusFormModal.vue';
import DollarGeneralFexaVisitScheduleFormModal from '@/components/admin/integrationReferences/Fexa/VisitScheduleFormModal.vue';

export default {
  name: 'FexaAssignmentManager',
  components: {
    DollarGeneralFexaAssignmentStatusFormModal,
    DollarGeneralFexaVisitScheduleFormModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      integrationReference: null,
      statusFormModalKey: Date.now(),
      scheduleFormModalKey: Date.now(),
    };
  },
  mounted() {
    this.getIntegrationReference();
  },

  methods: {
    onIntegrationReferenceChange(reference) {
      this.$emit('status-changed', (reference.dollar_general_fexa_assignment_record || reference.rite_aid_fexa_assignment_record)?.status);
      this.integrationReference = reference;
      this.statusFormModalKey = Date.now();
    },
    onWorkOrderChange(workOrder) {
      this.$emit('input', workOrder);
      this.scheduleFormModalKey = Date.now();
    },
    getIntegrationReference() {
      this.$integrationReferencesAPI.get(this.integrationReferenceId).then((currentReference) => {
        this.onIntegrationReferenceChange(currentReference);
    });
    },
  },
  computed: {
    isDollarGeneralFexa() {
      return !!this.value.dollar_general_fexa_assignment_reference_id;
    },
    isRiteAidFexa() {
      return !!this.value.rite_aid_fexa_assignment_reference_id;
    },
    integrationReferenceId() {
      return this.value.dollar_general_fexa_assignment_reference_id || this.value.rite_aid_fexa_assignment_reference_id;
    },
    assignment() {
      return this.integrationReference?.dollar_general_fexa_assignment_record || this.integrationReference?.rite_aid_fexa_assignment_record;
    },
    statusModalId() {
      return `fexa-assignment-${this.integrationReferenceId}-status-modal`;
    },
    scheduleModalId() {
      return `fexa-visit-${this.integrationReferenceId}-schedule-modal`;
    },
    recommendedVisitDate() {
      return this.assignment?.initial_arrival_deadline;
    },
  },
};
</script>

