<template>
  <b-popover
    :target="popoverId"
    triggers="focus"
    placement="left"
    boundary="window"
  >
    <b-row class="mb-3">
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        Title
      </b-col>
      <b-col class="text-right">
        {{ value.title }}
      </b-col>
    </b-row>

    <b-row
      v-if="value.content"
      class="mb-3"
    >
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        Content
      </b-col>
      <b-col class="text-right">
        {{ value.content }}
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        Category
      </b-col>
      <b-col class="text-right">
        <b-badge
          variant="secondary"
          class="font-size-sm"
        >
          {{ value.category | humanizedFormat | capitalizeFormat }}
        </b-badge>
      </b-col>
    </b-row>

    <b-row
      v-if="value.user"
      class="mb-3"
    >
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        User
      </b-col>
      <b-col class="text-right">
        <b-link :to="{ name: 'user', params: { id: value.user_id } }">
          {{ value.user ? value.user.name : "" }}
        </b-link>
      </b-col>
    </b-row>

    <b-row
      v-if="value.sent_at"
      class="mb-3"
    >
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        Sent At
      </b-col>
      <b-col class="text-right">
        {{ value.sent_at | dateTimeFormat }}
      </b-col>
    </b-row>

    <b-row
      v-if="value.read_at"
      class="mb-3"
    >
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        Read At
      </b-col>
      <b-col class="text-right">
        {{ value.read_at | dateTimeFormat }}
      </b-col>
    </b-row>

    <b-row
      v-if="value.recipients"
      class="mb-3"
    >
      <b-col
        cols="auto"
        class="mr-auto text-muted font-w500 font-size-sm"
      >
        Email Recipients
      </b-col>
      <b-col class="text-right">
        {{ formatRecipients(value.recipients) }}
      </b-col>
    </b-row>
  </b-popover>
</template>

<script>
export default {
  name: 'CommunicationsItemPopover',
  props: {
    value: Object,
  },
  methods: {
    formatRecipients(recipients) {
      return recipients.join(', ');
    },
  },
  computed: {
    popoverId() {
      return `communications-${this.value.id}-popover`;
    },
  },
};
</script>

<style scoped>
  .popover {
    max-width: 400px;
  }
</style>
