<template>
  <sfs-form-modal
    :id="modalId"
    title="Create Memo"
    @on-hide="resetFormData"
  >
    <memos-form
      @on-submit="createMemo"
      @on-save="redirectToMemo"
      @on-cancel="closeModal"
      :key="formKey"
      :date="new Date().toISOString()"
      :user="user"
      :user_id="userId"
      :client="client"
      :client_id="clientId"
    />
  </sfs-form-modal>
</template>

<script>
import MemosForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'MemosAddModal',
  mixins: [formModalMixin],
  components: {
    MemosForm,
  },
  props: {
    user: Object,
    client: Object,
  },
  data() {
    return {
      modalId: 'memo-add-modal',
    };
  },
  methods: {
    createMemo(data, handleCreate) {
      handleCreate(this.$memosAPI.create(data));
    },
    redirectToMemo(memo) {
      this.$router.push({ name: 'memo', params: { id: memo.id } });
    },
  },
  computed: {
    userId() {
      return this.user?.id;
    },
     clientId() {
      return this.client?.id;
    },
  },
};
</script>
