<template>
  <b-modal
    :id="modalId"
    :title="title"
    size="xl"
    no-close-on-backdrop
    hide-footer
    static
    lazy
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <communications-table
      :columns="communicationsColumns"
      :list-provider="listProvider"
      :reference-id="referenceId"
      :table-classes="{ 'mb-0': true }"
    />

    <hr class="mt-4">

    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"
          block
          id="communications-list-close-button"
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import CommunicationsTable from './Table.vue';
import { LIST_MODAL_COMMUNICATION_TABLE_NAME } from '@/constants/communications';

export default {
  name: 'CommunicationsListModal',
  components: {
    CommunicationsTable,
  },
  props: {
    listProvider: {
      type: Function,
      required: true,
    },
    referenceId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Communication History',
    },
  },
  data() {
    return {
      communicationsTableName: LIST_MODAL_COMMUNICATION_TABLE_NAME,
      communications: [],
      modalId: 'communications-list-modal',
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
  computed: {
    communicationsColumns() {
      return this.$store.getters.columnsForTable(this.communicationsTableName);
    },
  }
};
</script>
