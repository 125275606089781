<template>
  <sfs-base-list-filter
    title="Filter Memos"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            :placeholder="`Search by ${$store.getters.translate('client', 'client')} or Location`"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            :label="`${$store.getters.translate('client', 'Client')}`"
            label-for="popover-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="popover-filter-client_id"
              placeholder="All"
              :value="client"
              :show-client-code="false"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Location"
            label-for="popover-filter-location_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <location-select
              id="popover-filter-location_id"
              placeholder="All"
              :value="location"
              @input="onLocationChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Created By"
            label-for="popover-filter-user_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <employee-select
              id="popover-filter-user_id"
              placeholder="All"
              :value="user"
              @input="onUserChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Created Dates"
            label-for="popover-filter-date_between"
            label-cols-lg="5"
            content-cols-lg="7"
            class="mb-0"
          >
            <sfs-date-range-picker
              id="popover-filter-date_between"
              v-model="filters.date_between"
              placeholder="All"
              @on-change="onCompletedBetweenChange"
            />
          </b-form-group>

          <small class="form-text text-muted mb-3">Filter on dates the memo was created</small>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>

<script>
import listFilterMixin from '@/mixins/listFilterMixin';
import ClientSelect from '@/components/admin/clients/Select.vue';
import LocationSelect from '@/components/admin/locations/Select.vue';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';

import { MEMO_TABLE_COLUMN_OPTIONS, MEMO_TABLE_DEFAULT_COLUMNS, MEMO_TABLE_NAME, MEMO_FORM_LABELS } from '@/constants/memos';

export default {
  name: 'MemosListFilter',
  mixins: [listFilterMixin],
  components: {
    ClientSelect,
    LocationSelect,
    EmployeeSelect,
  },
  data() {
    return {
      availableColumns: MEMO_TABLE_COLUMN_OPTIONS,
      fieldLabels: MEMO_FORM_LABELS,
      tableName: MEMO_TABLE_NAME,
      date_between: [
        this.date_from && this.$options.filters.dateFormat(this.date_from),
        this.date_to && this.$options.filters.dateFormat(this.date_to),
      ],
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = MEMO_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onLocationChange(location) {
      this.filters.location_id = location ? location.id.toString() : '';
      this.location = location;
    },
    onUserChange(user) {
      this.filters.user_id = user ? user.id.toString() : '';
      this.user = user;
    },
    onCompletedBetweenChange(dates) {
      if (dates[0]) {
        this.filters.date_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.date_from = '';
      }

      if (dates[1]) {
        this.filters.date_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.date_to = '';
      }
    },
    loadInitialFilters() {
      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadLocation = new Promise((resolve) => {
        if (this.initialFilters.location_id) {
          this.$locationsAPI.get(this.initialFilters.location_id).then((location) => {
            resolve(location);
          });
        } else {
          resolve(null);
        }
      });

      const loadUser = new Promise((resolve) => {
        if (this.initialFilters.user_id) {
          this.$usersAPI.get(this.initialFilters.user_id).then((user) => {
            resolve(user);
          });
        } else {
          resolve(null);
        }
      });

      Promise.all([loadClient, loadLocation, loadUser]).then((values) => {
        this.client = values[0];
        this.location = values[1];
        this.user = values[2];
        this.applyFilters();
      });
    },
  },
};
</script>
