<template>
  <div v-if="invoice">
    <base-page-heading
      :title="title"
      show-back-button
    >
      <template #secondary-title>
        <b-badge
          v-if="invoice.finalized_at"
          class="mr-2"
          variant="success"
        >
          Finalized
        </b-badge>
        <b-badge
          v-else
          class="mr-2"
          variant="secondary"
        >
          Open
        </b-badge>
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', invoice)"
          class="mr-2"
          variant="primary"
          v-b-tooltip.hover
          title="Finalize the Invoice"
          @click="finalizeInvoice"
        >
          <i class="fa fa-check" />
          Finalize
        </b-button>
        <b-button
          v-if="$can('update', invoice)"
          variant="primary"
          v-b-modal.invoice-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Invoice"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-button
          variant="outline-secondary"
          v-b-modal.invoice-email-modal
          v-b-tooltip.hover
          title="Email the Invoice"
          class="mr-2"
        >
          <i class="fa fa-envelope" />
        </b-button>

        <sfs-download-button
          v-if="invoiceFormat"
          v-b-tooltip.hover
          :filename="filename"
          :file-provider="getTemplate"
          title="Download the Invoice"
          class="mr-2"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', invoice)"
            @click="destroyInvoice"
          >
            <i class="fa fa-trash mr-1" />
            Delete Invoice
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy_with_items', invoice)"
            @click="destroyInvoiceWithItems"
          >
            <i class="fa fa-trash mr-1" />
            Delete Invoice With Items
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', invoice) && !invoice.uploaded_at"
            @click="markAsUploaded"
          >
            <i class="fa fa-upload mr-1" />
            Mark as Uploaded
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item v-b-modal.communications-list-modal>
            <i class="fa fa-history" />
            Email History
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: invoice.id, model: 'Invoice' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-5">
        <b-col
          md="12"
          lg="6"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Date
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ invoice.date | dateFormat }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Due Date
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ invoice.due_date | dateFormat }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Emailed
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="invoice.emailed_at">
                  {{ invoice.emailed_at | dateTimeFormat }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Managers
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="client.managers?.length > 0">
                  <b-link
                    v-for="(manager, index) in client.managers"
                    :key="index"
                    :to="{ name: 'user', params: { id: manager.id } }"
                  >
                    {{ manager.name }}<span v-if="index != client.managers.length - 1">,</span>
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Delivery Method
                </b-col>
              </b-row>

              <b-row>
                <b-col v-if="invoice.client.invoice_delivery_method">
                  <span id="delivery-method-notes">
                    {{ invoice.client.invoice_delivery_method | humanizedFormat | capitalizeFormat }}
                  </span>
                  <b-popover
                    v-if="invoice.client.invoice_delivery_notes"
                    target="delivery-method-notes"
                    triggers="hover"
                    placement="top"
                  >
                    {{ invoice.client.invoice_delivery_notes }}
                  </b-popover>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Uploaded
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="invoice.uploaded_at">
                  {{ invoice.uploaded_at | dateTimeFormat }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="12"
          lg="6"
        >
          <b-row>
            <b-col
              cols="12"
              sm="7"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  {{ clientTranslation }}
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <b-link :to="{ name: 'client', params: { id: invoice.client_id } }">
                    {{ invoice.client.name }}
                  </b-link>
                  <div v-if="client.contact_name">
                    {{ client.contact_name }}
                  </div>
                </b-col>
              </b-row>

              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Billing Address
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div>{{ invoice.client.address }}</div>
                  <div>
                    {{
                      {
                        city: invoice.client.city,
                        state: invoice.client.state,
                        zip_code: invoice.client.zip_code,
                      } | locationFormat
                    }}
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="5"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Subtotal
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <money-format
                    :value="invoice.subtotal.amount"
                    :currency-code="invoice.subtotal.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>

              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Taxes
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <money-format
                    :value="invoice.taxes.amount"
                    :currency-code="invoice.taxes.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>

              <hr class="mt-0 mb-2">

              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Total
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <money-format
                    :value="invoice.balance.amount"
                    :currency-code="invoice.balance.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <invoice-items-list-filter
            :invoice="invoice"
            :initial-filters="filters"
            @filters-applied="applyFilters"
            @data-changed="refreshTable"
          />
          <invoice-items-table
            :columns="invoiceItemsColumns"
            :invoice-id="$route.params.id.toString()"
            :append-to-query="false"
            :filters="filters"
            :key="tableKey"
            @data-changed="getInvoice"
          />
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <invoices-edit-modal
      v-if="$can('update', invoice)"
      v-model="invoice"
    />
    <email-modal v-model="invoice" />
    <communications-list-modal
      :list-provider="$communicationsAPI.getInvoiceCommunications"
      :reference-id="invoice.id"
      title="Email History"
    />
  </div>
</template>

<script>
import InvoicesEditModal from '@/components/admin/invoices/EditModal.vue';
import InvoiceItemsTable from '@/components/admin/invoiceItems/Table.vue';
import InvoiceItemsListFilter from '@/components/admin/invoiceItems/ListFilter.vue';
import EmailModal from '@/components/admin/invoices/EmailModal.vue';
import CommunicationsListModal from '@/components/admin/communications/ListModal.vue';
import { INVOICE_ITEM_TABLE_NAME } from '@/constants/invoiceItems';
import translationMixin from '@/mixins/translationMixin';
import { DateTime } from 'luxon';

export default {
  name: 'InvoicesShow',
  mixins: [translationMixin],
  components: {
    InvoicesEditModal,
    InvoiceItemsTable,
    InvoiceItemsListFilter,
    EmailModal,
    CommunicationsListModal,
  },
  data() {
    return {
      invoice: null,
      client: {},
      invoiceItemsTable: INVOICE_ITEM_TABLE_NAME,
      tableKey: Date.now(),
      filters: {
        text: this.text,
      },
    };
  },
  mounted() {
    this.getInvoice();
  },
  computed: {
    title() {
      return `Invoice ${this.invoice.number}`;
    },
    invoiceFormat() {
      return this.invoice.invoice_format;
    },
    hasAnyAddressFields() {
      return (
        (this.client.address || '').length > 0 ||
        (this.client.city || '').length > 0 ||
        (this.client.state || '').length > 0 ||
        (this.client.zip_code || '').length > 0
      );
    },
    filename() {
      return `${this.invoice.filename}.${this.invoice.invoice_format.file_type}`;
    },
    invoiceItemsColumns() {
      return this.$store.getters.columnsForTable(this.invoiceItemsTable);
    }
  },
  methods: {
    getInvoice() {
      this.$invoicesAPI
        .get(this.$route.params.id)
        .then((invoice) => (this.invoice = invoice))
        .then(() => this.getClient())
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getClient() {
      this.$clientsAPI
        .get(this.invoice.client_id)
        .then((client) => (this.client = client))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    refreshTable() {
      this.tableKey = Date.now();
    },
    getTemplate() {
      return this.$invoicesAPI.getTemplate(this.$route.params.id, this.invoice.invoice_format.file_type);
    },
    applyFilters({ filters }) {
      this.filters = Object.assign({}, filters);
    },
    finalizeInvoice() {
      this.$swal({
        text: 'Are you sure you want to finalize the Invoice?',
        preConfirm: () => this.$invoicesAPI.finalizeInvoice(this.$route.params.id)
      }).then(() => {
        this.getInvoice();
      });
    },
    markAsUploaded() {
      this.$swal({
        text: 'Are you sure you want to mark this Invoice as uploaded?',
        preConfirm: () => this.$invoicesAPI.update(this.$route.params.id, { uploaded_at: DateTime.now().toISO() })
      }).then(() => {
        this.getInvoice();
      });
    },
    destroyInvoice() {
      this.$swal({
        text: 'Are you sure you want to delete the Invoice? This cannot be undone.',
        preConfirm: () => this.$invoicesAPI.destroy(this.$route.params.id).catch((error) => {
          let message = 'We\'ve experienced an issue while trying to delete the invoice, Please try again.';
          if (error.response && error.response.status === 403) {
            message = 'This invoice cannot be deleted - only invoices without items can be deleted.';
          }
          this.$bvToast.toast(message, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          return false;
        }),
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: 'invoices' });
        }
        result.timer;
      });
    },
    destroyInvoiceWithItems() {
      this.$swal({
        text: 'Are you sure you want to delete the Invoice with Items? This cannot be undone.',
        preConfirm: () => this.$invoicesAPI.destroyWithItems(this.$route.params.id).catch((error) => {
          let message = 'We\'ve experienced an issue while trying to delete the invoice, Please try again.';
          if (error.response && error.response.status === 403) {
            message = 'This invoice cannot be deleted.';
          }
          this.$bvToast.toast(message, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          return false;
        }),
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: 'invoices' });
        }
        result.timer;
      });
    },
  },
};
</script>
