<template>
  <sfs-form-modal
    :id="modalId"
    title="Add an Item"
    @on-hide="resetFormData"
  >
    <quote-item-form
      :quote="quote"
      category="labor"
      :quantity="1"
      @on-submit="createItem"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :is-dollar-general-fexa-quote="isDollarGeneralFexaQuote"
      :is-rite-aid-fexa-quote="isRiteAidFexaQuote"
    />
  </sfs-form-modal>
</template>

<script>
import QuoteItemForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'QuoteItemAddModal',
  mixins: [formModalMixin],
  components: {
    QuoteItemForm,
  },
  props: {
    quote: { type: Object, required: true },
    isDollarGeneralFexaQuote: { type: Boolean, default: false },
    isRiteAidFexaQuote: { type: Boolean, default: false },
  },
  data() {
    return {
      modalId: 'quote-item-add-modal',
    };
  },
  methods: {
    createItem(data, handleCreate) {
      handleCreate(this.$quotesAPI.createItem(this.quote.id, data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
};
</script>
