<template>
  <sfs-form-modal
    :id="modalId"
    class="text-left"
    @on-hide="resetFormData"
    title="Update Assignment Status"
  >
    <b-form
      :key="formKey"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="fexa-assignment-status_id"
          >
            <status-select
              name="fexa-assignment-status_id"
              :value="status"
              placeholder="Select a Status"
              :integration-reference-id="value.id"
              @input="onStatusChanged"
              :select-class="{ 'is-invalid': $v.form.status_id.$dirty && $v.form.status_id.$error }"
              :is-dollar-general-fexa="isDollarGeneralFexa"
            />
            <b-form-invalid-feedback
              v-if="$v.form.status_id.$dirty"
              class="fexa-assignment-status_id-feedback"
            >
              <span v-if="!$v.form.status_id.required">Please enter a status.</span>
              <span v-if="!$v.form.status_id.serverFailed">{{ serverErrors.status_id }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            class="fexa-assignment-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            class="fexa-assignment-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { required } from 'vuelidate/lib/validators';
import StatusSelect from '@/components/admin/integrationReferences/Fexa/AssignmentStatusSelect.vue';
export default {
  name: 'DollarGeneralFexaAssignmentStatusFormModal',
  mixins: [formMixin, formModalMixin],
  components: {
    StatusSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    formModalId: {
      type: String,
      required: true,
    },
    isDollarGeneralFexa: { type: Boolean, default: false },
  },
  data() {
    return {
      modalId: this.formModalId,
      status: null,
      form: {
        status_id: null,
      },
    };
  },
  validations: {
    form: {
      status_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('status_id');
        },
      },
    },
  },
  methods: {
    onStatusChanged(status) {
      this.status = status;
      this.form.status_id = status ? status.id : null;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }

      this.processing = true;

      this.updateEndpoint(this.value.id, { status_id: this.form.status_id }).then((integrationReference) => {
        this.$emit('on-save', integrationReference);
        this.closeModal();
        this.processing = false;
      }).catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
      });
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
  computed: {
    updateEndpoint() {
      return this.isDollarGeneralFexa ? this.$integrationReferencesAPI.updateDollarGeneralFexaAssignmentStatus : this.$integrationReferencesAPI.updateRiteAidFexaAssignmentStatus;
    },
  }
};
</script>
