<template>
  <b-row class="mb-3">
    <b-col>
      <b-row class="mb-1 pb-0 memo-input-row">
        <b-col>
          <markdown-editor
            :value="value.content"
            @input="(content) => handleContentChanged(content)"
          />
        </b-col>
      </b-row>
      <photo-uploader
        :id="value.id"
        :photos="photos"
        :remove-photo="removePhoto"
        :upload-url="`/memos/${value.id}/photos`"
        @on-photo-uploaded="(photo) => addNewItemPhoto(photo)"
        @on-destroy="(photo) => destroyNewItemPhoto(photo)"
      />
    </b-col>
  </b-row>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import PhotoUploader from '@/components/shared/PhotoUploader.vue';
import MarkdownEditor from '@/components/shared/MarkdownEditor.vue';

export default {
  name: 'EditContent',
  mixins: [formMixin],
  components: {
    PhotoUploader,
    MarkdownEditor,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    inputRef: {
      type: String,
    },
  },
  data() {
    return {
      photos: [],
    };
  },
  mounted() {
    this.getMemoPhotos();
  },
  methods: {
    getMemoPhotos() {
      return this.$memosAPI.getPhotos(this.value.id)
        .then((response) => {
          this.photos = response.photos;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    handleContentChanged(newContent) {
      this.$emit('on-content-changed', newContent);
      if (newContent.length > 0) {
        this.$memosAPI
        .update(this.$route.params.id, { content: newContent })
        .then((memo) => {
          this.$emit('input', memo);
        });
      }
    },
    removePhoto(photoId) {
      return this.$memosAPI.destroyMemoPhoto(this.value.id, photoId);
    },
    addNewItemPhoto(photo) {
      this.photos.push(photo);
    },
    destroyNewItemPhoto(photo) {
      const photoIndex = this.photos.findIndex((currPhoto) => currPhoto.id === photo.id);
      if (photoIndex !== -1) {
        this.photos.splice(photoIndex, 1);
      }
    },
  },
  computed: {
    internalRef() {
      return this.inputRef || `memo-ref-${this.value.id}`;
    },
  },
};
</script>
