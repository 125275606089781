<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$memosAPI.getMemos"
    provider-key="memos"
    :columns="translatedColumns(modelName, columns)"
    :filters="{ ...filters }"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(title)="data">
      <b-link :to="{ name: 'memo', params: { id: data.item.id } }">
        {{ data.item.title }}
      </b-link>
    </template>

    <template #cell(date)="data">
      <b-link :to="{ name: 'memo', params: { id: data.item.id } }">
        {{ data.item.date | dateFormat }}
      </b-link>
    </template>

    <template #cell(location_id)="data">
      <b-link
        :to="{ name: 'location', params: { id: data.item.location_id } }"
        v-b-tooltip.hover.window
        :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
      >
        {{ data.item.location.store_number }}
        <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        {{ { address: data.item.location.street, city: data.item.location.city, state: data.item.location.state } | locationFormat }}
      </div>
    </template>

    <template #cell(client_id)="data">
      <b-link
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>

    <template #cell(user_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.user_id } }">
        {{ data.item.user.name }}
      </b-link>
    </template>

    <template #cell(assigned_to_id)="data">
      <b-link
        v-if="data.item.assigned_to"
        :to="{ name: 'user', params: { id: data.item.assigned_to.id } }"
      >
        {{ data.item.assigned_to.name }}
      </b-link>
      <b-col v-else>
        -
      </b-col>
    </template>

    <template #cell(updated_at)="data">
      {{ data.item.updated_at | dateTimeFormat }}
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { MEMO_TABLE_DEFAULT_COLUMNS, MEMO_MODEL_NAME } from '@/constants/memos';
export default {
  name: 'MemosTable',
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => MEMO_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: MEMO_MODEL_NAME,
    };
  },
};
</script>
