<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-alert
            v-model="showErrors"
            class="mb-3"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
            <p
              class="mb-0"
              v-for="(error, index) in serverErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-img
            width="68"
            height="68"
            class="photo mr-2 mt-2"
            v-for="(photo, index) in photos"
            :key="photo.id"
            :src="photo.url"
            @click="() => openPhotoCarousel(index)"
            v-b-modal="`${modalId}`"
          />

          <div :class="`item-dropzone-container item-dropzone-${id}-previews`" />

          <vue-dropzone
            ref="myVueDropzone"
            class="item-dropzone mr-2 mt-2"
            use-custom-slot
            :include-styling="false"
            :id="`item-dropzone-${id}`"
            :options="dropzoneOptions"
            @vdropzone-files-added="handleUploadStarted"
            @vdropzone-success="handleSuccess"
            @vdropzone-error="handleError"
            @vdropzone-queue-complete="() => uploading = false"
          >
            <b-spinner
              v-if="uploading"
              class="opacity-75"
              label="Uploading..."
            />
            <i
              v-else
              class="opacity-75 si si-camera fa-2x"
            />
          </vue-dropzone>
        </b-col>
      </b-row>

      <photo-carousel-modal
        :id="id"
        :photos="photos"
        :modal-id="modalId"
        :remove-photo-api="removePhoto"
        :photo-index="carouselStartingIndex"
        @on-destroy="(photo) => $emit('on-destroy', photo)"
      />
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import { adminPath } from '@/api/helpers/apiPath';
import formMixin from '@/mixins/formMixin';
import dropzoneDefaults from '@/utils/dropzoneDefaults';
import PhotoCarouselModal from '@/components/shared/PhotoCarouselModal.vue';
export default {
  name: 'PhotoUploader',
  mixins: [formMixin],
  components: {
    PhotoCarouselModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    removePhoto: {
      type: Function,
      required: true,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showErrors: false,
      uploading: false,
      carouselStartingIndex: 0,
    };
  },
  computed: {
    dropzoneOptions() {
      return {
        ...dropzoneDefaults,
        url: `${axios.defaults.baseURL}/${adminPath(this.uploadUrl)}`,
        headers: { Authorization: this.$store.getters.token },
        dictDefaultMessage: '',
        previewsContainer: `.item-dropzone-${this.id}-previews`,
        createImageThumbnails: false,
        thumbnailWidth: 68,
        thumbnailHeight: 68,
      };
    },
    modalId() {
      return `photos-item-carousel-${this.id}`;
    },
  },
  methods: {
    handleSuccess(_file, photo) {
      this.$emit('on-photo-uploaded', photo);
    },
    handleError(_file, error) {
      this.processServerErrors(error?.errors);
      this.showErrors = this.baseServerErrors.length > 0 || Object.keys(this.serverErrors).length > 0;
      this.uploading = false;
    },
    handleUploadStarted(files) {
      if (files.length > 0) {
        this.uploading = true;
      }
    },
    openPhotoCarousel(index) {
      this.carouselStartingIndex = index;
    },
  },
};
</script>

<style #scoped>
  .photo, .item-dropzone, .item-dropzone .dz-message, .item-dropzone-container {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
  .photo, .item-dropzone .dz-message,  .item-dropzone-container img {
    border-radius: 16px;
  }
  .item-dropzone .dz-message {
    color: #343a40;
    background-color: #eff1f4;
    border-color: #eff1f4;
    height: 68px;
    width: 68px;
    cursor: pointer;
  }
  .item-dropzone-container .dz-details,
  .item-dropzone-container .dz-progress,
  .item-dropzone-container .dz-error-message,
  .item-dropzone-container .dz-error-mark,
  .item-dropzone-container .dz-success-mark {
    display: none;
  }
</style>
