<template>
  <div v-if="memo">
    <base-page-heading
      title="Memo"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('update', 'Memo')"
          variant="primary"
          v-b-modal.memo-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Memo"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-button
          v-if="$can('update', 'Memo') && !memo.assigned_to_id"
          variant="outline-secondary"
          v-b-modal.memo-assign-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Assign the Memo"
        >
          <i class="fa fa-user-plus mr-1" /> Assign
        </b-button>

        <sfs-download-button
          :filename="`Memo ${memo.date} - ${memo.client.name}.pdf`"
          label="Summary"
          :provider-params="{ id: memo.id }"
          :file-provider="$memosAPI.getPDF"
          v-b-tooltip.hover
          title="Download the Summary PDF"
          class="mr-2"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('update', 'Memo')"
            v-b-modal.memo-assign-modal
            :key="`${memoKey}-${memo.assigned_to_id ? 'reassign' : 'assign'}-dropdown`"
          >
            <i class="fa fa-user-edit mr-1" />
            {{ memo.assigned_to_id ? 'Reassign' : 'Assign' }}
          </b-dropdown-item>

          <b-dropdown-item v-b-modal="`memo-${memo.id}-email-modal`">
            <i class="fa fa-envelope mr-1" />
            Email
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Memo')"
            @click="destroyMemoDialog(memo.id)"
          >
            <i class="fa fa-trash mr-1" />
            Delete
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: memo.id, model: 'Memo' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="gapy-3">
        <b-col md="8">
          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Client
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <b-link :to="{ name: 'client', params: { id: memo.client.id } }">
                {{ memo.client.name }}
              </b-link>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <b-row class="text-muted font-w500 mb-1">
                <b-col>
                  Location
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-link
                    :to="{ name: 'location', params: { id: location.id } }"
                    v-b-tooltip.hover.window
                    :title="`${location.street}, ${location.city}, ${location.state}, ${location.zip_code}`"
                  >
                    <span>{{ location.store_number }}</span>
                    <span v-if="location.name"> - {{ location.name }}</span>
                  </b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  {{ { address: location.street, city: location.city, state: location.state } | locationFormat }} {{ location.zip_code }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Caller
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="memo.caller">
              {{ memo.caller }}
            </b-col>
            <b-col v-else>
              -
            </b-col>
          </b-row>
        </b-col>

        <b-col md="4">
          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Date
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              {{ memo.date | dateFormat }}
            </b-col>
          </b-row>

          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Assigned User
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col v-if="memo.assigned_to">
              <b-link :to="{ name: 'user', params: { id: memo.assigned_to.id } }">
                {{ memo.assigned_to.name }}
              </b-link>
            </b-col>
            <b-col v-else>
              -
            </b-col>
          </b-row>

          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Created By
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-link :to="{ name: 'user', params: { id: memo.user.id } }">
                {{ memo.user.name }}
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr class="my-3">

      <b-row class="mb-3">
        <b-col>
          <h4>{{ memo.title }}</h4>
        </b-col>
      </b-row>

      <edit-content
        :value="memo"
        :input-ref="`memo-${memo.id}`"
      />
    </div>

    <!-- Modals -->
    <memos-edit-modal
      v-if="$can('update', 'Memo')"
      v-model="memo"
    />

    <email-modal
      :value="memo"
      :key="`${memoKey}-email-modal`"
      @input="updateMemo"
    />

    <assign-modal
      v-if="memo && $can('update', 'Memo')"
      :key="`${memoKey}-assign-modal`"
      :value="memo"
      @input="onMemoChanged"
    />
  </div>
</template>

<script>
import MemosEditModal from '@/components/admin/memos/EditModal.vue';
import EmailModal from '@/components/admin/memos/EmailModal.vue';
import EditContent from '@/components/admin/memos/EditContent.vue';
import AssignModal from '@/components/admin/memos/AssignModal.vue';

export default {
  name: 'MemosShow',
  components: {
    MemosEditModal,
    EmailModal,
    EditContent,
    AssignModal,
  },
  data() {
    return {
      memo: null,
      memoKey: `${Date.now()}`,
    };
  },
  mounted() {
    this.getMemo();
  },
  computed: {
    location() {
      return this.memo.location;
    },
  },
  methods: {
    updateMemo(memo) {
      this.memo = memo;
      this.memoKey = `${Date.now()}`;
    },
    getMemo() {
      return this.$memosAPI
        .get(this.$route.params.id)
        .then((memo) => {
          this.memo = memo;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    destroyMemoDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$memosAPI.destroy(id),
      }).then((result) => {
        if (result.value) {
          this.memo = result.value;
          this.$router.push({ name: 'memos' });
        }
        result.timer;
      });
    },
    onMemoChanged(memo) {
      this.memo = memo;
      this.memoKey = `${Date.now()}`;
    },
  },
};
</script>
