<template>
  <sfs-base-table
    :class="tableClasses"
    :data-provider="listProvider"
    :data-id="referenceId"
    provider-key="communications"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(title)="data">
      <div>{{ data.item.title }}</div>
      <b-badge
        variant="secondary"
        class="font-size-sm"
      >
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(sent_at)="data">
      <span class="text-secondary">
        {{ data.item.sent_at | dateTimeFormat }}
      </span>
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(status)="data">
      <b-badge :variant="variantForStatus(data.item.status)">
        {{ statusForItem(data.item.status) | humanizedFormat | capitalizeFormat }}
      </b-badge>
      <div v-if="data.item.status_description">
        {{ data.item.status_description }}
      </div>
    </template>
    <template #cell(read_at)="data">
      <span
        v-if="data.item.read_at"
        class="text-secondary"
      >
        {{ data.item.read_at | dateTimeFormat }}
      </span>
    </template>
    <template #cell(details)="data">
      <b-button
        href="#"
        tabindex="0"
        :id="`communications-${data.item.id}-popover`"
        size="sm"
        variant="link"
      >
        <i class="fa fa-eye" />
      </b-button>
      <communications-item-popover v-model="data.item" />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { COMMUNICATION_TABLE_DEFAULT_COLUMNS } from '@/constants/communications';
import CommunicationsItemPopover from '@/components/admin/communications/ItemPopover';

export default {
  name: 'CommunicationsTable',
  mixins: [tablePropsMixin],
  components: {
    CommunicationsItemPopover,
  },
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    listProvider: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      default: () => COMMUNICATION_TABLE_DEFAULT_COLUMNS,
    },
    tableClasses: {
      type: Object,
      default: () => new Object({ 'border-bottom': true }),
    },
  },
  methods: {
    formatRecipients(recipients) {
      return recipients.join(', ');
    },
    statusForItem(status) {
      if (status) {
        return status;
      }

      return 'unknown';
    },
    variantForStatus(status) {
      if (status === 'bounced') {
        return 'danger';
      }
      if (status === 'read') {
        return 'success';
      }

      return 'secondary';
    }
  },
};
</script>
