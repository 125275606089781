import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import fileFormDataSubmission from '@/utils/fileFormDataSubmission';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getImports(params) {
    return axios.get(adminPath('/imports?') + prepareQueryString(params)).then((response) => {
      return {
        imports: response.data.map((imported) => an('Import', imported)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getStatusOptions() {
    return axios.get(adminPath('/imports/status_options'));
  },
  getCategoryOptions() {
    return axios.get(adminPath('/imports/category_options'));
  },
  checkOutWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/check_out'));
  },
  cancelWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/cancel'));
  },
  updateWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/update'));
  },
  reopenWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/reopen'));
  },
  officetraxWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/officetrax'));
  },
  officetraxWorkOrdersPhotosPushCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/officetrax_photos_push'));
  },
  officetraxWorkOrdersCompletionsPushCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/officetrax_completions_push'));
  },
  assignWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/assign'));
  },
  locationsCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/locations'));
  },
  serviceChannelWorkOrdersPushCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/service_channel_push'));
  },
  serviceChannelWorkOrdersPhotosPushCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/service_channel_photos_push'));
  },
  technicianPaymentItemsCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/technician_payment_items'));
  },
  technicianPaymentItemsFromWorkOrderCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/technician_payment_items_from_work_order'));
  },
  invoiceItemsCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/invoice_items'));
  },
  deleteInvoicesCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/invoices/delete_invoices'));
  },
  technicianServicesCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/technician_services'));
  },
  technicianServicesDeletionCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/technician_services_deletion'));
  },
  recurringChargesCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/recurring_charges'));
  },
  recurringPaymentsCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/recurring_payments'));
  },
  recurringWorkOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/recurring_work_orders'));
  },
  usersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/users'));
  },
  clientsCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/clients'));
  },
  workOrderChargesCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_order_charges'));
  },
  updateWorkOrderChargesCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_order_charges/update'));
  },
  workOrdersCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders'));
  },
  taxRatesCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/tax_rates'));
  },
  removeWorkOrdersFromInvoiceCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/remove_from_invoice'));
  },
  removeWorkOrdersFromTechnicianPaymentCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/work_orders/remove_from_technician_payment'));
  },
  recurringWorkOrdersDeletionCreate(data) {
    return fileFormDataSubmission(data, adminPath('/imports/recurring_work_orders_deletion'));
  },
  getCheckOutWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/check_out/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getCancelWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/cancel/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getUpdateWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/update/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getReopenWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/reopen/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getOfficetraxWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/officetrax/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getOfficetraxWorkOrdersPhotosPushTemplate() {
    return axios.get(adminPath('/imports/work_orders/officetrax_photos_push/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getOfficetraxWorkOrdersCompletionsPushTemplate() {
    return axios.get(adminPath('/imports/work_orders/officetrax_completions_push/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getAssignWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/assign/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getServiceChannelWorkOrdersPhotosPushTemplate() {
    return axios.get(adminPath('/imports/work_orders/service_channel_photos_push/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getServiceChannelWorkOrdersPushTemplate() {
    return axios.get(adminPath('/imports/work_orders/service_channel_push/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getTechnicianServicesTemplate() {
    return axios.get(adminPath('/imports/technician_services/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getTechnicianServicesDeletionTemplate() {
    return axios.get(adminPath('/imports/technician_services_deletion/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getTechnicianPaymentItemsTemplate() {
    return axios.get(adminPath('/imports/technician_payment_items/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getTechnicianPaymentItemsFromWorkOrderTemplate() {
    return axios.get(adminPath('/imports/technician_payment_items_from_work_order/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getInvoiceItemsTemplate() {
    return axios.get(adminPath('/imports/invoice_items/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getDeleteInvoicesTemplate() {
    return axios.get(adminPath('/imports/invoices/delete_invoices/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getRecurringChargesTemplate() {
    return axios.get(adminPath('/imports/recurring_charges/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getRecurringPaymentsTemplate() {
    return axios.get(adminPath('/imports/recurring_payments/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getRecurringWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/recurring_work_orders/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getUsersTemplate() {
    return axios.get(adminPath('/imports/users/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getClientsTemplate() {
    return axios.get(adminPath('/imports/clients/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getLocationsTemplate() {
    return axios.get(adminPath('/imports/locations/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getWorkOrdersTemplate() {
    return axios.get(adminPath('/imports/work_orders/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getTaxRatesTemplate() {
    return axios.get(adminPath('/imports/tax_rates/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getWorkOrderChargesTemplate() {
    return axios.get(adminPath('/imports/work_order_charges/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getUpdateWorkOrderChargesTemplate() {
    return axios.get(adminPath('/imports/work_order_charges/update/template?') + prepareQueryString(), { responseType: 'blob' });
  },
  getRemoveWorkOrdersFromInvoiceTemplate() {
    return axios.get(adminPath('/imports/work_orders/remove_from_invoice/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getRecurringWorkOrdersDeletionTemplate() {
    return axios.get(adminPath('/imports/recurring_work_orders_deletion/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
  getRemoveWorkOrdersFromTechnicianPaymentTemplate() {
    return axios.get(adminPath('/imports/work_orders/remove_from_technician_payment/template?') + prepareQueryString(), {
      responseType: 'blob',
    });
  },
};
