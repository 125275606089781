<template>
  <b-row v-if="value.id">
    <b-col>
      <b-row class="mb-1 pb-0 walkthrough-item-input-row">
        <b-col
          cols="11"
          class="mr-auto"
        >
          <b-textarea
            class="walkthrough-item-text-area ml-1"
            :ref="internalRef"
            :rows="1"
            :max-rows="500"
            lazy
            trim
            :value="value.content"
            @keydown.native="handleCmdEnter"
            @change="handleContentChanged"
          />
        </b-col>
        <b-col
          cols="auto"
          class="text-right"
        >
          <b-dropdown
            toggle-class="walkthrough-item-link"
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <i class="fa fa-ellipsis-v" />
            </template>
            <b-dropdown-item
              v-b-modal="modalId"
            >
              Move Note
            </b-dropdown-item>

            <b-dropdown-item
              @click="removeItem"
            >
              Delete Note
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <photo-uploader
        :id="value.id"
        :photos="value.photos"
        :remove-photo="removePhoto"
        :upload-url="`/walkthrough_items/${value.id}/photos`"
        @on-photo-uploaded="(photo) => $emit('on-photo-uploaded', photo)"
        @on-destroy="(photo) => $emit('on-photo-destroyed', photo)"
      />
      <move-item-modal
        :value="value"
        :sections="sections"
        @on-save="(walkthroughItem) => this.$emit('on-moved', walkthroughItem)"
      />
    </b-col>
  </b-row>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import PhotoUploader from '@/components/shared/PhotoUploader.vue';
import MoveItemModal from '@/components/admin/walkthroughItems/MoveItemModal.vue';

export default {
  name: 'EditItem',
  mixins: [formMixin],
  components: {
    PhotoUploader,
    MoveItemModal,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      required: true,
    },
    inputRef: {
      type: String,
    },
  },
  methods: {
    handleContentChanged(newContent) {
      this.$emit('on-content-changed', newContent);
      if (newContent.length > 0) {
        this.$walkthroughsAPI
        .updateItem(this.value.id, { content: newContent })
        .then((walkthroughItem) => {
          this.$emit('input', walkthroughItem);
        });
      }
    },
    handleCmdEnter(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode == 13) {
        this.$nextTick(() => {
          this.$refs[this.internalRef]?.blur();
        });
      }
    },
    removeItem() {
      this.$swal({
        text: 'Are you sure you want to remove this item?',
        preConfirm: () => {
          return this.$walkthroughsAPI.destroyItem(this.value.id).catch(() => {
            this.$bvToast.toast('We\'ve experienced an issue trying to remove your item', {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
            return false;
          });
        },
      }).then((result) => {
        if (result.value) {
          this.$emit('on-destroy');
        }
        result.timer;
      });
    },
    removePhoto(photoId) {
      return this.$walkthroughsAPI.destroyItemPhoto(this.value.id, photoId);
    },
  },
  computed: {
    internalRef() {
      return this.inputRef || `walkthrough-item-ref-${this.value.id}`;
    },
    modalId() {
      return `move-walkthrough-item-${this.value.id}-modal`;
    },
  },
  watch: {
    'value.id': function () {
      if (this.internalRef) {
        this.$nextTick(() => {
          this.$refs[this.internalRef]?.focus();
        });
      }
    }
  },
};
</script>

<style #scoped>
  .walkthrough-item-text-area.form-control {
    border: none;
    background-color: #FFFFFF;
    overflow-y: auto!important;
    min-height: 42px;
    field-sizing: content;
  }
   .walkthrough-item-text-area.form-control:focus {
    background-color: #F8F9FA;
   }
</style>
