<template>
  <sfs-form-modal
    :id="modalId"
    class="text-left"
    @on-hide="resetFormData"
    title="Manage Scheduled Visit"
  >
    <b-form
      :key="formKey"
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.scheduled_date"
            label-for="fexa-visit-schedule-scheduled_date"
            :class="{ 'is-invalid': $v.form.scheduled_date.$dirty && $v.form.scheduled_date.$error }"
          >
            <sfs-date-time-picker
              id="fexa-visit-schedule-scheduled_date"
              v-model="form.scheduled_date"
              :class="{ 'is-invalid': $v.form.scheduled_date.$dirty && $v.form.scheduled_date.$error }"
              :time-zone="value.location && value.location.time_zone"
            />
            <small
              class="form-text text-muted"
            >
              <b-link @click="setRecommendedVisitSchedule"> Click here </b-link> to apply the recommended schedule date of {{ recommendedVisitSchedule | dateTimeFormat(value.location.time_zone) }}
            </small>
            <b-form-invalid-feedback
              v-if="$v.form.scheduled_date.$dirty"
              id="fexa-visit-schedule-scheduled_date-feedback"
            >
              <span v-if="!$v.form.scheduled_date.serverFailed">{{ serverErrors.scheduled_date }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            class="fexa-visit-schedule-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            class="fexa-visit-schedule-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'FexaVisitScheduleFormModal',
  mixins: [formMixin, formModalMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
    formModalId: {
      type: String,
      required: true,
    },
    recommendedVisitSchedule: {
      type: String,
    },
  },
  data() {
    return {
      modalId: this.formModalId,
      form: {
        scheduled_date: this.value.integration_metadata?.scheduled_date,
      },
    };
  },
  validations: {
    form: {
      scheduled_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('scheduled_date');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }

      this.processing = true;

      this.$workOrdersAPI
      .update(this.value.id, { integration_metadata: { scheduled_date: this.form.scheduled_date } })
      .then((workOrder) => {
        this.resetServerErrors();
        this.$v.$reset();
        this.$emit('on-save', workOrder);
        this.closeModal();
      })
      .catch((error) => {
        this.processServerErrors(error.response.data.errors);
      });
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
    setRecommendedVisitSchedule() {
      this.form.scheduled_date = this.recommendedVisitSchedule;
    },
  },
};
</script>
